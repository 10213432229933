import { useState, useEffect } from 'react'
import { logger } from '@lib/logger'

interface AddParticipantResponse {
  id: string
}

declare global {
  interface Window {
    growsurf?: {
      ready: boolean
      addParticipant: (options: { 
        email: string
        firstName?: string
        lastName?: string
      }) => Promise<AddParticipantResponse>
      getReferrerId: () => string | null
    }
  }
}

type ParticipantData = {
  email: string
  firstName?: string
  lastName?: string
  metadata?: Record<string, any>
}

export const useGrowSurf = (campaignId: string | undefined) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [referrerId, setReferrerId] = useState<string | null>(null)
  
  useEffect(() => {
    if (!campaignId) {
      logger.error('GrowSurf not loaded: No campaign ID provided')
      return
    }

    if (document.getElementById('growsurf-js')) {
      if (window.growsurf) {
        setIsLoaded(true)
        const refId = window.growsurf.getReferrerId()
        if (refId) setReferrerId(refId)
      }
      return
    }

    const script = document.createElement('script')
    script.src = 'https://app.growsurf.com/growsurf.js?v=2.0.0'
    script.async = true
    script.id = 'growsurf-js'
    script.setAttribute('grsf-campaign', campaignId)
    
    const checkGrowSurf = () => {
      if (window.growsurf) {
        setIsLoaded(true)
        const refId = window.growsurf.getReferrerId()
        if (refId) {
          setReferrerId(refId)
          logger.info('GrowSurf referral detected', { referrerId: refId })
        }
      } else {
        setTimeout(checkGrowSurf, 100)
      }
    }

    script.onload = checkGrowSurf
    
    script.onerror = (error: string | Event) => {
      logger.error('Failed to load GrowSurf script', {}, error instanceof Error ? error : new Error(String(error)))
    }
    
    document.head.appendChild(script)
    
    return () => {
      const scriptElement = document.getElementById('growsurf-js')
      if (scriptElement) {
        document.head.removeChild(scriptElement)
      }
    }
  }, [campaignId])

  const addParticipant = async (data: ParticipantData): Promise<AddParticipantResponse | null> => {
    if (!isLoaded || !window.growsurf) {
      logger.error(
        'Failed to add participant to GrowSurf: SDK not loaded', 
        { email: data.email }
      )
      return null
    }
    
    try {
      const result = await window.growsurf.addParticipant({
        ...data.metadata,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })
      return result
    } catch (error) {
      logger.error(
        'Error adding participant to GrowSurf', 
        { email: data.email, referrerId },
        error as Error
      )
      return null
    }
  }

  return {
    isLoaded,
    referrerGrowsurfId: referrerId,
    wasReferred: !!referrerId,
    addParticipant
  }
}