import React, { useMemo, useEffect } from 'react'
import { Box, Text, Button, useDisclosure, useToast } from '@chakra-ui/react'

import { AssignedWorksheets } from '../../handlers/sessions/components/in-session-assist-panel/worksheets/assigned'
import { AssignWorksheetsModal } from '@handlers/sessions/components/shared/assist/worksheets/assign-worksheets-modal'

import { CompletedWorksheetList } from '../../handlers/sessions/components/in-session-assist-panel/worksheets/completed-worksheets-list'
import { useExperienceManager } from '@hooks'
import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { useStoreActions } from 'easy-peasy'
import { usePatientControllerAssistEnroll } from '~/clinician-api'
import { AssignableWorksheet } from '@handlers/sessions/types'

export const AssistWorksheets = ({ patient }: { patient: any }) => {
  const [
    selectedWorksheet,
    setSelectedWorksheet
  ] = React.useState<AssignableWorksheet | null>(null)
  const toast = useToast()
  const {
    isOpen: isAssignWorksheetsModalOpen,
    onOpen: openAssignWorksheetsModal,
    onClose: closeAssignWorksheetsModal
  } = useDisclosure()

  const { isPlusPlanEnabled } = useExperienceManager()

  useEffect(() => {
    if (!isAssignWorksheetsModalOpen) {
      setSelectedWorksheet(null)
    }
  }, [isAssignWorksheetsModalOpen])

  const { data } = usePatientControllerGetCheckInScores(patient.id, {
    query: {
      initialData: {
        scores: [],
        versions: []
      }
    }
  })

  const {
    mutateAsync: enrollInAssist,
    isLoading: isEnrolling
  } = usePatientControllerAssistEnroll()

  const handleEnroll = (values: any) => {
    if (selectedWorksheet) {
      enrollInAssist(
        {
          patientId: patient.id,
          data: {
            email: values.email,
            phone: values.phone,
            assessments: [],
            checkIns: [
              {
                daysOfWeek: values.checkIn.daysOfWeek,
                timeOfDay: values.checkIn.timeOfDay,
                frequencyType: values.checkIn.frequency,
                suggestionId: selectedWorksheet.contentSuggestionId,
                checkInId: selectedWorksheet.checkInId
              }
            ]
          }
        },
        {
          onSuccess: () => {
            closeAssignWorksheetsModal()
            toast({
              title: 'Assigned!',
              status: 'success',
              isClosable: true,
              duration: 1200
            })
          },
          onError: () => {
            closeAssignWorksheetsModal()
            toast({
              title: 'Error',
              description: 'There was an error assigning this measure',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          }
        }
      )
    }
  }

  const scoresWithCheckIn = useMemo(() => {
    return data?.scores.map(score => ({
      ...score,
      checkIn: data.versions.find(v => v.versionId === score.versionId)
    }))
  }, [data])

  // @ts-ignore
  const { openModal } = useStoreActions(state => state.modals.worksheetScores)

  const handleBrowseLibraryClick = () => {
    openAssignWorksheetsModal()
  }

  const handleSetWorksheet = (worksheet: any) => {
    setSelectedWorksheet(worksheet ? { checkInId: worksheet.id } : null)
  }

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="xsmall"
        >
          <Text fontWeight="bold">Assigned</Text>
          {isPlusPlanEnabled && !patient.is_archived && (
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseLibraryClick}
            >
              Browse library
            </Button>
          )}
        </Box>
        <AssignedWorksheets patient={patient} />
        <Box>
          <Text fontWeight="bold" mt="medium" mb="xsmall">
            Completed
          </Text>
          <CompletedWorksheetList
            scoresWithCheckIn={scoresWithCheckIn!}
            onClickWorksheet={s => {
              openModal({
                patientId: patient.id,
                preselectedCheckInScoreId: s.id
              })
            }}
          />
        </Box>
      </Box>
      {isAssignWorksheetsModalOpen && (
        <AssignWorksheetsModal
          onEnroll={handleEnroll}
          isEnrolling={isEnrolling}
          worksheet={selectedWorksheet}
          client={patient}
          isOpen={isAssignWorksheetsModalOpen}
          onClose={closeAssignWorksheetsModal}
          setWorksheet={handleSetWorksheet}
        />
      )}
    </Box>
  )
}
