import React from 'react'
import { Container, useToast, Box } from '@chakra-ui/react'
import { 
  useOrganizationControllerCreateCustomProgressNoteTemplate,
  useOrganizationControllerGetCustomProgressNoteTemplate
} from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { useHistory, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ProgressNoteTemplateForm, FormValues } from './form'
import { Loading } from '@components'
export const Edit = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const toast = useToast()
  const { organizationId } = useExperienceManager()
  const queryClient = useQueryClient()
  
  const { data: template, isLoading } = useOrganizationControllerGetCustomProgressNoteTemplate(
    organizationId,
    id,
    {
      query: {
        refetchOnMount: true,
        cacheTime: 0,
      }
    }
  )

  

  const { mutate: createCustomProgressNoteTemplate } = useOrganizationControllerCreateCustomProgressNoteTemplate()

  const handleSubmit = (values: FormValues) => {
    createCustomProgressNoteTemplate({
      organizationId,
      data: { 
        name: values.name,
        previousTemplateId: id,
        sections: values.sections.map((section, index) => ({
          name: section.name,
          position: index,
          sampleOutputs: section.sampleOutputs
        }))
      }
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['organizationControllerGetCustomProgressNoteTemplates'] })
        toast({
          title: 'Template updated',
          description: 'Your template has been updated',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        history.replace('/settings/templates')
      }
    })
  }

  if (isLoading) {
    return <Loading />
  }

  if (!template) {
    return <Box>Template not found</Box>
  }

  const initialValues: FormValues = {
    name: template.name,
    sections: template?.sections?.map(section => ({
      name: section.name,
      id: section.id,
      instructions: section.instructions,
      sampleOutputs: section.sampleOutputs
    })) || []
  }

  return (
    <Container mt="40px" maxWidth="1142px">
      <ProgressNoteTemplateForm
        template={template}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={() => history.push('/settings/templates')}
      />
    </Container>
  )
}

export default Edit 