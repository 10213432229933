import React, { createContext, FC, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { endpoints } from '@api'
import { useLocation } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { StoreModel } from 'src/store/types'
import { useOrganizationControllerGetOutboundEhrSettings } from '~/clinician-api'

interface ExperienceManagerContextType {
  isEvidenceBasedCareEnabled: boolean
  isDocumentationAutomationEnabled: boolean
  isDocumentationAutomationOnly: boolean
  isPlusPlanEnabled: boolean
  hasPlusPlanFreeTrial: boolean
  daysRemainingInPlusPlanFreeTrial: number
  isDocumentationAutomationFreePlan: boolean
  documentationAutomationSessionsRemaining: number
  documentationAutomationSessionsUsed: number
  documentationAutomationSessionsAllotted: number
  hasDocumentationAutomationSubscription: boolean
  documentationAutomationFreeTierSessionLimitReached: boolean
  clinicianCount: number
  isAdmin: boolean
  isSuperAdmin: boolean
  isDocumentationAutomationEnterprise: boolean
  isSafari: boolean
  isExtension: boolean
  isExtensionMinimized: boolean
  toggleIsExtensionMinimized: () => void
  isWidget: boolean
  isIntegratedUI: boolean
  paywallData: any
  isDemoClinician: boolean
  isSessionRecordingV2Enabled: boolean
  outboundEhrSettings: { copyNoteButtonText?: string } | undefined
  plusPlanFreeTrialEndDate: string
  isPlusPlanFreeTrialBannerEnabled: boolean
  prescriberNoteTypeOptionsLabel: string | null
  notePreferencesEnabled: boolean
  isDateOfBirthEnabled: boolean
  showRulaUI: boolean
  showPartnerUI: boolean
  isAudioCaptureV2Enabled: boolean
  isPresignedUrlV2Enabled: boolean
  showReferralButton: boolean
  customNoteTemplatesEnabled: boolean
  showCustomTemplateInstructions: boolean
  customNoteTemplatesFullRelease: boolean
  isFinishAssistSetupV2Enabled: boolean
  memoryEnabled: boolean
  organizationId: string
  isV2CancellationReasonsEnabled: boolean
  isOnboardingV2Enabled: boolean
}

export const ExperienceManagerContext = createContext<
  ExperienceManagerContextType
>({} as ExperienceManagerContextType)

interface ExperienceManagerProviderProps {
  children: React.ReactNode
}

export const ExperienceManagerProvider: FC<ExperienceManagerProviderProps> = ({
  children
}) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const location = useLocation()

  const [isExtension, setIsExtension] = useState(false)
  const [isWidget, setIsWidget] = useState(false)
  const [isExtensionMinimized, setIsExtensionMinimized] = useState(false)

  const isIntegratedUI = isWidget || isExtension

  useEffect(() => {
    const extension = location.pathname.includes('/extension')
    const widget = location.pathname.includes('/widget')
    if (extension) {
      setIsExtension(true)
    }

    if (widget) {
      setIsWidget(true)
    }
  }, [location.pathname])

  const { data, isLoading } = useQuery(
    endpoints.getPaywallValidation.getCacheId(),
    endpoints.getPaywallValidation.request
  )

  const {
    data: outboundEhrSettings
  } = useOrganizationControllerGetOutboundEhrSettings(
    user!.clinic.organization_id
  )

  if (isLoading) return null

  const {
    documentationAutomationSessionsRemaining,
    documentationAutomationSessionsUsed,
    documentationAutomationSessionsAllotted,
    isDocumentationAutomationFree,
    isDocumentationAutomationEnabled: isDocAutomationEnabled,
    clinicianCount,
    isDocumentationAutomationEnterprise,
    isDocumentationAutomationPartner,
    hasDocumentationAutomationSubscription,
    isEBCEnabled: isEvidenceBasedCareEnabled,
    hasPlusPlanFreeTrial,
    daysRemainingInPlusPlanFreeTrial,
    isPlusPlanEnabled: _isPlusPlanEnabled,
    plusPlanFreeTrialEndDate
  } = data as any

  const isDocumentationAutomationEnabled = isDocAutomationEnabled
  const isAdmin = !!user?.roles.find(
    (role: { name: string }) =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )

  const isSuperAdmin = !!user?.roles.find(
    (role: { name: string }) => role.name === 'superadmin'
  )
  const isDemoClinician = flagsmith.hasFeature(FlagsmithFeatures.DEMO_CLINICIAN)

  const isAudioCaptureV2Enabled = flagsmith.hasFeature(
    FlagsmithFeatures.AUDIO_CAPTURE_V2
  )

  const isPresignedUrlV2Enabled = flagsmith.hasFeature(
    FlagsmithFeatures.PRESIGNED_URL_V2
  )

  const isV2CancellationReasonsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.CANCELLATION_REASONS_V2
  )

  const isSafari =
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1 &&
    navigator.userAgent.indexOf('CriOS') == -1

  const toggleIsExtensionMinimized = () => {
    setIsExtensionMinimized((isMinimized: boolean) => !isMinimized)
    const eventType = !isExtensionMinimized ? 'BP_MINIMIZE' : 'BP_EXPAND'
    // eslint-disable-next-line
    parent.postMessage({ type: eventType }, '*')
  }

  // Including feature flag override for now for alpha group carryover
  const isPlusPlanEnabled =
    flagsmith.hasFeature(FlagsmithFeatures.ASSIST) || _isPlusPlanEnabled

  const isSessionRecordingV2Enabled =
    !isEvidenceBasedCareEnabled && !isIntegratedUI

  const isPlusPlanFreeTrialBannerEnabled =
    hasPlusPlanFreeTrial &&
    flagsmith.hasFeature(FlagsmithFeatures.PLUS_PLAN_FREE_TRIAL_BANNER) &&
    daysRemainingInPlusPlanFreeTrial <= 7

  const customNoteTemplatesEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.CUSTOM_NOTE_TEMPLATES
  )

  const showCustomTemplateInstructions = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_CUSTOM_TEMPLATE_INSTRUCTIONS
  )

  const prescriberNoteTypeOptionsLabel =
    user?.organization?.prescriberNoteTypeOptionsLabel || null
  // this is a temp solution for the rula pilot
  const notePreferencesEnabled = !prescriberNoteTypeOptionsLabel
  const isDateOfBirthEnabled = !!prescriberNoteTypeOptionsLabel

  const showRulaUI = flagsmith.hasFeature(FlagsmithFeatures.RULA_UI_EXPERIENCE)
  const showPartnerUI = isDocumentationAutomationPartner

  const isFinishAssistSetupV2Enabled = flagsmith.hasFeature(
    FlagsmithFeatures.FINISH_ASSIST_SETUP_V2
  )

  const customNoteTemplatesFullRelease = flagsmith.hasFeature(
    FlagsmithFeatures.CUSTOM_NOTE_TEMPLATES_FULL_RELEASE
  )

  const isOnboardingV2Enabled = flagsmith.hasFeature(
    FlagsmithFeatures.ONBOARDING_V2
  )

  const memoryEnabled = !!(
    flagsmith.hasFeature(FlagsmithFeatures.MEMORY) ||
    (user?.created_at && new Date(user?.created_at) > new Date('2025-03-28T16:00:00Z'))
  )

  const showReferralButton =
    !isDocumentationAutomationEnterprise &&
    isDocumentationAutomationEnabled &&
    !isEvidenceBasedCareEnabled &&
    !isDocumentationAutomationPartner

  return (
    <ExperienceManagerContext.Provider
      value={{
        isEvidenceBasedCareEnabled,
        isDocumentationAutomationEnabled,
        isDocumentationAutomationOnly:
          isDocumentationAutomationEnabled && !isEvidenceBasedCareEnabled,
        isPlusPlanEnabled,
        hasPlusPlanFreeTrial,
        daysRemainingInPlusPlanFreeTrial,
        hasDocumentationAutomationSubscription,
        documentationAutomationSessionsRemaining,
        documentationAutomationSessionsUsed,
        documentationAutomationSessionsAllotted,
        isDocumentationAutomationFreePlan: isDocumentationAutomationFree,
        documentationAutomationFreeTierSessionLimitReached:
          isDocumentationAutomationFree &&
          documentationAutomationSessionsRemaining < 1,
        clinicianCount: clinicianCount,
        isAdmin,
        isSuperAdmin,
        isDocumentationAutomationEnterprise,
        isSafari,
        isExtension,
        isExtensionMinimized,
        toggleIsExtensionMinimized,
        isWidget,
        isIntegratedUI,
        isSessionRecordingV2Enabled,
        paywallData: data,
        isDemoClinician,
        outboundEhrSettings,
        plusPlanFreeTrialEndDate,
        isPlusPlanFreeTrialBannerEnabled,
        prescriberNoteTypeOptionsLabel,
        notePreferencesEnabled,
        isDateOfBirthEnabled,
        showRulaUI,
        showPartnerUI,
        isAudioCaptureV2Enabled,
        isPresignedUrlV2Enabled,
        showReferralButton,
        customNoteTemplatesEnabled,
        showCustomTemplateInstructions,
        customNoteTemplatesFullRelease,
        isFinishAssistSetupV2Enabled,
        memoryEnabled,
        organizationId: user?.clinic?.organization_id as string,
        isV2CancellationReasonsEnabled,
        isOnboardingV2Enabled
      }}
    >
      {children}
    </ExperienceManagerContext.Provider>
  )
}
