import {
  ArrowDownIcon,
  Button,
  IconButton,
  PersonAddIcon,
  SearchIcon
} from '@blueprinthq/joy'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast
} from '@chakra-ui/react'
import flagsmith from 'flagsmith'
import React, { useCallback, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import NotificationBadge from './components/notification-badge'

import { FlagsmithFeatures } from '../../constants/flagsmith'
import { readClients, readEhrAppts } from '../../constants/permissions'
import { usePermissions } from '../../hooks'
import WhiteLogo from '../../images/brand/white_logo.svg'
import { cloudinaryAssetToUrl } from '../../utilities'
import { SearchInput, UserSettingsDropdown } from './components'
import { useExperienceManager } from '@hooks'
import { Gift } from '../../components/icons'
import { trackEvent } from '@lib/clinician-tracking'

export const NavBarDesktopView = ({ user, focusMode, paywallData }) => {
  const { hasPermission } = usePermissions()
  const searchInputRef = useRef(null)
  const showBilling = user.organization.showBilling
  const canEnrollClients = user.organization.canEnrollClients
  const {
    isAdmin,
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnterprise,
    isDocumentationAutomationFreePlan,
    documentationAutomationSessionsUsed,
    documentationAutomationSessionsAllotted,
    showPartnerUI,
    showReferralButton
  } = useExperienceManager()
  const history = useHistory()

  const showEhrIntegrationIssues = flagsmith.hasFeature(
    FlagsmithFeatures.EHR_INTEGRATION_ISSUES
  )

  const showEhrAppointments =
    flagsmith.hasFeature(FlagsmithFeatures.EHR_APPOINTMENTS) &&
    readEhrAppts.every(p => hasPermission(p))

  const showLibraryNav = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_LIBRARY_NAV
  )

  const [searchQuery, setSearchQuery] = useState('')
  const [isSearchOpen, setSearchOpen] = useState(false)

  const toggleSearchView = useCallback(() => {
    setSearchOpen(!isSearchOpen)
    if (!isSearchOpen) {
      setSearchQuery('')
      searchInputRef.current.focus()
    }
  }, [isSearchOpen])

  const isClinical = readClients.every(perm => hasPermission(perm))

  if (focusMode) {
    const logoWidth = 143
    return (
      <Flex justify={'space-between'} alignItems={'center'} flexGrow={1}>
        <Image loading="eager" src={WhiteLogo} />
        <Box width={logoWidth}></Box>
      </Flex>
    )
  }

  return (
    <>
      <Link to={isClinical ? '/dashboard' : '/reports/claims'}>
        <Image
          loading="eager"
          src={WhiteLogo}
          sx={{
            objectFit: 'contain',
            mr: 'xlarge'
          }}
        />
      </Link>
      <Flex
        sx={{
          alignItems: 'center',
          position: 'relative',
          flex: 1
        }}
      >
        <NavComponentsSection
          isClinical={isClinical}
          isSearchOpen={isSearchOpen}
          showBilling={showBilling}
          toggleSearchView={toggleSearchView}
          showEhrIntegrationIssues={showEhrIntegrationIssues}
          showEhrAppointments={showEhrAppointments}
          showLibraryNav={showLibraryNav}
        />
        {isClinical ? (
          <Box
            sx={{
              position: 'absolute',
              opacity: isSearchOpen ? 1 : 0,
              zIndex: 2,
              transition: 'all 160ms linear',
              pointerEvents: isSearchOpen ? 'all' : 'none',
              ml: '-4px'
            }}
          >
            <SearchInput
              ref={searchInputRef}
              value={searchQuery}
              onSearchClose={toggleSearchView}
              onChange={value => setSearchQuery(value)}
              onClear={() => {
                setSearchQuery('')
                searchInputRef.current.focus()
              }}
              sx={{
                bg: 'white',
                width: '440px',
                caretColor: 'primary',
                color: 'black'
              }}
            />
          </Box>
        ) : null}
      </Flex>
      <Flex>
        {!isDocumentationAutomationEnterprise &&
          !isEvidenceBasedCareEnabled &&
          !showPartnerUI && (
            <Flex alignItems="center" gap="4px">
              <Button
                as={Link}
                to="/settings/update-your-plan"
                variant="ghost"
                color="white"
                fontWeight="normal"
                size="md"
                _focus={{ outline: 'none' }}
              >
                {documentationAutomationSessionsUsed}/
                {documentationAutomationSessionsAllotted} sessions
              </Button>
              {isAdmin && isDocumentationAutomationFreePlan && (
                <Button
                  w="100px"
                  bg="black"
                  color="pale_blue"
                  m="0"
                  onClick={() => {
                    if (isDocumentationAutomationFreePlan) {
                      history.push(`/settings/plans`)
                    } else {
                      history.push(`/settings/update-your-plan`)
                    }
                  }}
                >
                  Upgrade
                </Button>
              )}
              {showReferralButton && (
                <Button
                  as={Link}
                  to="/refer"
                  variant="ghost"
                  border="solid 1px #FFFFFF1A"
                  color="white"
                  fontWeight="normal"
                  size="md"
                  _focus={{ outline: 'none' }}
                  onClick={() =>
                    trackEvent('Referral V2 -> Clicked Refer A Friend', {
                      source: 'Desktop Navbar'
                    })
                  }
                >
                  <HStack spacing="2">
                    <Gift fill="white" />
                    <Text color="white">Refer a friend</Text>
                  </HStack>
                </Button>
              )}
            </Flex>
          )}
        <UserSection
          user={user}
          isClinical={isClinical}
          canEnrollClients={canEnrollClients}
          paywallData={paywallData}
        />
      </Flex>
    </>
  )
}

/**
 * NavLink section; varies based on search view state, User Role permissions,
 * and feature flag status.
 */
const NavComponentsSection = ({
  isClinical,
  isSearchOpen,
  showBilling,
  toggleSearchView,
  showEhrIntegrationIssues,
  showEhrAppointments,
  showLibraryNav
}) => {
  const { hasPermission } = usePermissions()
  const notificationCount = 33
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled,
    isAdmin,
    isPlusPlanEnabled
  } = useExperienceManager()

  const showScribeClientList =
    !isEvidenceBasedCareEnabled && isDocumentationAutomationEnabled

  return (
    <Flex
      sx={{
        overflow: 'hidden',
        alignItems: 'center',
        opacity: !isSearchOpen ? 1 : 0,
        zIndex: 1,
        transition: 'all 300ms ease-out'
      }}
    >
      {isClinical && (
        <>
          <IconButton
            aria-label="Search patients"
            icon={<SearchIcon fill={'white'} />}
            onClick={toggleSearchView}
            variant={'ghost'}
          />
          {isEvidenceBasedCareEnabled && (
            <Button
              as={Link}
              to="/dashboard"
              variant="ghost"
              color="white"
              fontWeight="normal"
              size="md"
              _focus={{ outline: 'none' }}
              paddingRight={notificationCount > 0 ? 'xsmall' : 'small'}
              rightIcon={<NotificationBadge />}
              onClick={e => {
                if (window.location.pathname === '/dashboard') {
                  window.location.reload()
                }
              }}
            >
              Dashboard
            </Button>
          )}

          <Button
            as={Link}
            to="/sessions"
            variant="ghost"
            color="white"
            fontWeight="normal"
            size="md"
            _focus={{ outline: 'none' }}
            data-click-event-name="Navbar -> Clicked Sessions"
          >
            Sessions
          </Button>
          {isEvidenceBasedCareEnabled && (
            <Button
              as={Link}
              to="/patients/active"
              variant="ghost"
              color="white"
              fontWeight="normal"
              size="md"
              _focus={{ outline: 'none' }}
            >
              Clients
            </Button>
          )}
          {showScribeClientList && (
            <Button
              as={Link}
              to="/scribe/client-list"
              variant="ghost"
              color="white"
              fontWeight="normal"
              size="md"
              _focus={{ outline: 'none' }}
              id="pendo-scribe-client-list"
            >
              Clients
            </Button>
          )}
        </>
      )}
      {isPlusPlanEnabled &&
        !isEvidenceBasedCareEnabled &&
        hasPermission('read:own:reports:outcomes') && (
          <Menu>
            <MenuButton
              variant="ghost"
              as={Button}
              color="white"
              fontWeight="normal"
              size="md"
              pr="xsmall"
              _focus={{ outline: 'none' }}
            >
              <Flex direction="row" align="center">
                <Text>Reports</Text>
                <ArrowDownIcon ml="2px" fill="white" />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem as={Link} to="/insights/treatment-outcomes">
                Treatment Outcomes
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      {isEvidenceBasedCareEnabled && (
        <Menu>
          <MenuButton
            variant="ghost"
            as={Button}
            color="white"
            fontWeight="normal"
            size="md"
            pr="xsmall"
            _focus={{ outline: 'none' }}
          >
            <Flex direction="row" align="center">
              <Text>Reports</Text>
              <ArrowDownIcon ml="2px" fill="white" />
            </Flex>
          </MenuButton>
          <MenuList>
            {hasPermission('read:clinic:reports:utilization') && (
              <MenuItem as={Link} to="/insights/utilization">
                Utilization
              </MenuItem>
            )}
            {hasPermission('read:own:reports:outcomes') && (
              <MenuItem as={Link} to="/insights/treatment-outcomes">
                Treatment Outcomes
              </MenuItem>
            )}
            {hasPermission('read:own:reports:outcomes') && (
              <MenuItem as={Link} to="/insights/client-satisfaction">
                Client Satisfaction
              </MenuItem>
            )}
            {hasPermission('*:org:billing:*') && showBilling && (
              <MenuItem as={Link} to="/reports/claims">
                Claims
              </MenuItem>
            )}
            {showEhrIntegrationIssues && (
              <MenuItem as={Link} to="/insights/ehr-integration-issues">
                EHR Integration Issues
              </MenuItem>
            )}
            {showEhrAppointments && (
              <MenuItem as={Link} to="/insights/ehr-appointments">
                EHR Appointments
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
      {isEvidenceBasedCareEnabled && isAdmin && (
        <Menu>
          <MenuButton
            variant="ghost"
            as={Button}
            color="white"
            fontWeight="normal"
            size="md"
            pr="xsmall"
            _focus={{ outline: 'none' }}
          >
            <Flex direction="row" align="center">
              <Text>Library</Text>
              <ArrowDownIcon ml="2px" fill="white" />
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} to="/library/worksheets">
              Worksheets
            </MenuItem>
            <MenuItem as={Link} to="/library/symptom-trackers">
              Symptom Trackers
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}

/**
 * User's account section dropdown.
 */
const UserSection = ({ user, canEnrollClients, paywallData }) => {
  const history = useHistory()
  const toast = useToast()
  const { hasPayingSubscription, clientCount, isLegacyPlan } = paywallData
  const disableEnrollment = !hasPayingSubscription && clientCount >= 5

  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  return (
    <>
      {canEnrollClients && isEvidenceBasedCareEnabled && (
        <Button
          size="md"
          data-click-event-name="Navbar -> Clicked New Client"
          aria-label="Add new client"
          onClick={() => {
            if (disableEnrollment) {
              toast({
                title: 'Please upgrade your plan to enroll additional clients.',
                description:
                  "You've reached the 3 client limit on the Starter plan.",
                status: 'error',
                duration: 3000,
                isClosable: true
              })
            } else {
              history.push('/new-client')
            }
          }}
          variant={'ghost'}
          _focus={{ outline: 'none' }}
        >
          <Box mr="xsmall" w="16px">
            <PersonAddIcon size="sm" mb="-3px" fill={'white'} />
          </Box>
          <Text color="white" fontWeight="normal">
            New Client
          </Text>
        </Button>
      )}
      <Box data-intercom-target="user-settings-dropdown">
        <Menu autoSelect={false}>
          <MenuButton
            variant="ghost"
            as={Button}
            color="white"
            fontWeight="normal"
            size="md"
            pr="xsmall"
            pl="xsmall"
            _focus={{ outline: 'none' }}
          >
            <Flex direction="row" align="center" justify="center">
              <Avatar
                width="24px"
                height="24px"
                src={cloudinaryAssetToUrl(user.avatar, {
                  transformations: {
                    resize: {
                      height: 128,
                      width: 128,
                      type: 'scale'
                    }
                  }
                })}
                name={`${user.first_name} ${user.last_name}`}
                bg="pale_gray"
                size="sm"
                mr="xsmall"
                fontWeight="normal"
              />
              <Text> {`${user.first_name}`}</Text>
              <ArrowDownIcon ml="2px" fill="white" />
            </Flex>
          </MenuButton>
          <MenuList pt="0" pb="0">
            <UserSettingsDropdown isLegacyPlan={isLegacyPlan} user={user} />
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}
