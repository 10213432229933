import { datadogLogs } from '@datadog/browser-logs'

type LogData = object | undefined

class Logger {
  private isLocal: boolean
  private isInitialized: boolean = false

  constructor() {
    this.isLocal = process.env.REACT_APP_ENV === 'development'
  }

  init() {
    if (this.isInitialized) return

    if (!this.isLocal) {
      datadogLogs.init({
        env: process.env.REACT_APP_ENV,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
        service: process.env.REACT_APP_DATADOG_SERVICE,
        version: process.env.REACT_APP_DD_VERSION,
        forwardErrorsToLogs: true,
        proxy: options =>
          `https://data-collector.blueprint-health.com${options.path}?${options.parameters}`
      })
    }

    this.isInitialized = true
  }

  debug(message: string, data?: LogData) {
    if (this.isLocal) {
      console.debug(message, data)
    } else {
      datadogLogs.logger.debug(message, data)
    }
  }

  info(message: string, data?: LogData) {
    if (this.isLocal) {
      console.info(message, data)
    } else {
      datadogLogs.logger.info(message, data)
    }
  }

  warn(message: string, data?: LogData) {
    if (this.isLocal) {
      console.warn(message, data)
    } else {
      datadogLogs.logger.warn(message, data)
    }
  }

  error(message: string, data?: LogData, error?: Error) {
    if (this.isLocal) {
      console.error(message, data, error)
    } else {
      datadogLogs.logger.error(message, data, error)
    }
  }
}

export const logger = new Logger()
